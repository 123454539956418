import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import routes from '../../../../enums/routes';
import { TStore } from '../../../../redux/store';
import CoreCard from '../../../molecules/CoreCard/CoreCard';
import { StyledLink } from '../../../atoms/Link/Link';
import config from '../../../../config/config.json';
import GuidedSelectionOptions from '../../../../enums/guidedSelection';
import { CoreSpecialistCardContent, SpecialistExcerpt, SpecialistTitle } from './styles';

const IsRHLOn = config.FEATURES.RHL === 'true';

export default function SpecialistSelection(): React.JSX.Element | null {
  const history = useNavigate();

  const { members } = useSelector((state: TStore) => state.quoteState.quote);
  const { navigateOptions, guidedSelection } = useSelector((state: TStore) => state.helpersState.helpers);
  const isGuided: boolean = guidedSelection === GuidedSelectionOptions.guided;

  const specialistTitle = isGuided ? 'We’ll source your specialist' : 'You choose your own specialist from our list';
  const specialistExcerpt = isGuided
    ? 'If you want to use a different specialist, you’ll need to pay towards the cost of treatment'
    : 'We’ll let you know which specialists we cover when you get in touch, and can book the appointment for you if you prefer';

  if (!IsRHLOn) return null;

  return (
    <CoreCard
      title="Choosing a specialist"
      bubbleTitle={members.length > 1 ? 'Applies to every member' : ''}
      testId="specialist-network-selection"
      cardBodyMobileMarginBottom="8px"
    >
      <CoreSpecialistCardContent>
        <SpecialistTitle>{specialistTitle}</SpecialistTitle>
        <SpecialistExcerpt>{specialistExcerpt}</SpecialistExcerpt>
        <Box textAlign="left">
          <StyledLink
            to=" "
            aria-label="change-your-choice-button-choosing-a-specialist"
            data-testid="change-your-choice-button-choosing-a-specialist"
            $marginTop="0"
            onClick={(e) => {
              e.preventDefault();
              history(routes.guidedSelection, navigateOptions);
            }}
          >
            Change your choice
          </StyledLink>
        </Box>
      </CoreSpecialistCardContent>
    </CoreCard>
  );
}
