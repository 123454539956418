import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Box, ListItem } from '@mui/material';
import { Text } from '@digitalportal-ui/core';
import { useMemo } from 'react';
import routes from '../../../../enums/routes';
import { TStore } from '../../../../redux/store';
import { CoreNetworkCardContent, NetworkExcerpt, NetworkTitle } from './styles';
import NetworkContent from '../../../../content/networkContent.json';
import RhlNetworkContent from '../../../../content/rhlNetworkContent.json';
import CoreCard from '../../../molecules/CoreCard/CoreCard';
import { StyledLink } from '../../../atoms/Link/Link';
import config from '../../../../config/config.json';
import GuidedSelectionOptions from '../../../../enums/guidedSelection';
import { ModalStyledList } from '../../hospitals/styles';

const IsRHLOn = config.FEATURES.RHL === 'true';

export default function HospitalNetworkSelection(): React.JSX.Element {
  const history = useNavigate();

  const { hospitalNetwork, members } = useSelector((state: TStore) => state.quoteState.quote);
  const { navigateOptions, guidedSelection } = useSelector((state: TStore) => state.helpersState.helpers);
  const isGuided: boolean = guidedSelection === GuidedSelectionOptions.guided;

  const networkOption = NetworkContent.find((x) => x.networkValue === hospitalNetwork);
  const rhlNetworkOption = RhlNetworkContent.find((x) => x.networkValue === hospitalNetwork);

  const cardTitle = IsRHLOn ? 'Choosing a hospital' : 'Choosing a specialist';
  const cardBubble = IsRHLOn ? 'Applies to every member' : 'Available to every member';

  const networkTitle = IsRHLOn ? `${rhlNetworkOption?.header} - ${rhlNetworkOption?.excerpt}` : networkOption?.header;
  const networkExcerptIsGuided = isGuided
    ? 'If you want to use a hospital that’s not on the list, you’ll need to pay towards the cost of treatment'
    : 'You can use any hospital from the full AXA Health list';
  const networkExcerpt = IsRHLOn ? networkExcerptIsGuided : networkOption?.body[0];

  const modalTitle = IsRHLOn ? 'A little more about how this works...' : networkTitle;
  const modalContentGuidedSelection = useMemo(
    () =>
      isGuided ? (
        <Box>
          <ModalStyledList>
            <ListItem>
              The specialist we source for you may not work at your nearest hospital, so you may need to travel further.
            </ListItem>
            <ListItem>
              If you want to use a specialist other than the one we source for you, or go to a hospital that’s not on
              your list, you’ll usually need to pay towards the cost. This may be a large amount.
            </ListItem>
          </ModalStyledList>
          <Text bold>Are you happy with this choice?</Text>
        </Box>
      ) : (
        <Box>
          <ModalStyledList>
            <ListItem>
              You can choose a specialist from our list, and any hospital from the full AXA Health list.{' '}
            </ListItem>
            <ListItem>
              We’ll usually cover the specialist’s fees in full. However, you may sometimes need to pay a top-up fee, if
              the specialist charges more than we usually pay. We’ll tell you about this when you get in touch with us
              about your treatment, and you’ll be able to switch to a specialist who doesn’t charge extra.
            </ListItem>
          </ModalStyledList>
          <Text bold>Are you happy with this choice?</Text>
        </Box>
      ),
    [isGuided],
  );

  const modalContent = IsRHLOn ? (
    modalContentGuidedSelection
  ) : (
    <Box>
      {networkOption?.body.map((text: string, index: number) => (
        <Text
          key={text.concat(index.toString())}
          variant="body1"
          style={{ marginBottom: text.includes('\n') ? '8px' : '0' }}
        >
          {text}
        </Text>
      ))}
    </Box>
  );

  return (
    <CoreCard
      title={cardTitle}
      bubbleTitle={members.length > 1 ? cardBubble : ''}
      modalTitle={modalTitle}
      modalChildren={modalContent}
      testId="hospital-network-selection"
      cardBodyMobileMarginBottom="8px"
    >
      <CoreNetworkCardContent>
        <NetworkTitle>{networkTitle}</NetworkTitle>
        <NetworkExcerpt>{networkExcerpt}</NetworkExcerpt>
        <Box textAlign="left">
          <StyledLink
            to=" "
            aria-label="change-your-choice-button-choosing-a-network"
            data-testid="change-your-choice-button-choosing-a-network"
            $marginTop="0"
            onClick={(e) => {
              e.preventDefault();
              history(routes.hospitals, navigateOptions);
            }}
          >
            Change your choice
          </StyledLink>
        </Box>
      </CoreNetworkCardContent>
    </CoreCard>
  );
}
