import { Box } from '@mui/material';
import { H2, Text, Modal, colors } from '@digitalportal-ui/core';
import { useState } from 'react';

import { useDispatch } from 'react-redux';
import { Wrapper, ImageGrid, CardBody, CardHeading, ModalContent } from './styles';
import { ReactComponent as ElderlyAtPcImage } from '../../../assets/svg/elderly_at_pc.svg';
import { StyledLink } from '../../atoms/Link/Link';
import { updateModalInfo } from '../../../redux/slices/helpers';
import { InfoBubble } from '../../templates/styles';
import { ColorsType } from '../../../redux/slices/helpers/helpers.types';

export type CoreCardProps = {
  title: string;
  subTitle?: string;
  bubbleTitle?: string;
  contentImage?: boolean;
  children?: React.ReactNode;
  modalTitle?: string;
  modalChildren?: React.ReactNode;
  testId: string;
  linkText?: string;
  cardBodyMarginBottom?: string;
  cardBodyMobileMarginBottom?: string;
  backgroundColor?: ColorsType;
};

function CoreCard({
  title,
  subTitle,
  bubbleTitle,
  contentImage,
  children,
  modalTitle,
  modalChildren,
  testId,
  linkText,
  cardBodyMarginBottom,
  cardBodyMobileMarginBottom,
  backgroundColor,
}: CoreCardProps): React.JSX.Element {
  const [findOutMoreOpen, setFindOutMoreOpen] = useState(false);
  const dispatch = useDispatch();

  return (
    <Wrapper $backgroundColor={backgroundColor} data-testid={testId}>
      {modalTitle && modalChildren && (
        <Modal
          variant="info"
          title={modalTitle}
          open={findOutMoreOpen}
          onClose={() => {
            setFindOutMoreOpen(false);
            dispatch(updateModalInfo({ isModalOpen: false, modalTitle: '' }));
          }}
          dataTestid={`modal-${testId}`}
        >
          <ModalContent>{modalChildren}</ModalContent>
        </Modal>
      )}
      <CardHeading>
        <Box style={{ textAlign: 'left' }}>
          <H2>{title}</H2>
          {subTitle && <Text data-testid="core-card-subtitle">{subTitle}</Text>}
        </Box>
        {bubbleTitle ? (
          <InfoBubble sx={{ mb: { xs: '8px' } }} $chipColor={colors.pacificBlue10}>
            {bubbleTitle}
          </InfoBubble>
        ) : null}
      </CardHeading>
      <CardBody $cardBodyMarginBottom={cardBodyMarginBottom} $cardBodyMobileMarginBottom={cardBodyMobileMarginBottom}>
        {children}
        {contentImage && (
          <ImageGrid item>
            <ElderlyAtPcImage data-testid={`core-card-image-${testId}`} />
          </ImageGrid>
        )}
      </CardBody>
      {modalTitle && modalChildren && (
        <Box textAlign="left">
          <StyledLink
            to=" "
            aria-label="find-out-more-button"
            data-testid={`find-out-more-button-${testId}`}
            $marginTop="0"
            onClick={(e) => {
              e.preventDefault();
              setFindOutMoreOpen(true);
              dispatch(updateModalInfo({ isModalOpen: true, modalTitle }));
            }}
          >
            {linkText || 'Find out more'}
          </StyledLink>
        </Box>
      )}
    </Wrapper>
  );
}
export default CoreCard;
