import { useNavigate } from 'react-router-dom';
import { Formik, Form, useFormikContext } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { Text } from '@digitalportal-ui/core';
import TextInputField from '../../molecules/TextInputField/TextInputField';
import { ContactSchema } from '../../../validationSchema';
import { Member, updateMember } from '../../../redux/slices/quote';
import { TStore } from '../../../redux/store';
import routes from '../../../enums/routes';
import { TextInputHolder } from './styles';
import PageIntroduction from '../../molecules/PageIntroduction/PageIntroduction';
import Header from '../../templates/Header/Header';
import PageNavigation from '../../templates/PageNavigation/PageNavigation';
import { Main, MessageBoxStyled } from '../../templates/styles';
import { emitCustomEvent, useCustomEventListener } from '../../../lib/utils/eventHandler';
import { ExternalStyledLink } from '../../atoms/Link/Link';
import config from '../../../config/config.json';

export default function ContactDetails(): React.JSX.Element {
  const history = useNavigate();
  const dispatch = useDispatch();
  const initialStateValues = useSelector((state: TStore) => state.quoteState.quote.members.find((member) => member.policyHolder)) ?? {} as Member;
  const navigateOptions = useSelector((state: TStore) => state.helpersState.helpers.navigateOptions);
  const privacyPolicyUrl = 'https://www.axahealth.co.uk/privacy-policy/';

  function Submitter() {
    const { submitForm } = useFormikContext<unknown>();
    useCustomEventListener('pageNavigationNext', () => {
      submitForm();
    });

    return null;
  }

  useCustomEventListener('pageNavigationBack', () => {
    history(routes.address, navigateOptions);
  });

  return (
    <>
      <Header />
      <Main maxWidth="lg">
        <Formik
          validateOnBlur
          initialValues={initialStateValues}
          validateOnMount
          onSubmit={async (values) => {
            dispatch(updateMember({ userEditIndex: 0, values }));
            history(routes.additionalMembers, navigateOptions);
          }}
          validationSchema={ContactSchema}
        >
          <Form>
            <PageIntroduction title="What are your contact details?" titleTestId="title" />
            <TextInputHolder
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  emitCustomEvent('pageNavigationNext');
                }
              }}
            >
              <TextInputField testId="textInputField-email" label="Email address" name="email" inputMode="email" />
              <TextInputField testId="textInputField-phone" maxLength={24} label="Phone number" name="phone" inputMode="numeric" />
              <MessageBoxStyled>
                <Text data-testid="solicitation-statement">
                  We’ll use these details to get in touch about your quote.
                  {config.FEATURES.OFFLINE_REDIRECT === 'true' ? ' Our agents will call you on the number you provide. ' : ' '}
                  See our{' '}
                  <ExternalStyledLink
                    data-testid="privacy-policy-link"
                    $inline
                    target="_blank"
                    rel="noreferrer"
                    href={privacyPolicyUrl}
                    onKeyDown={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    privacy policy
                  </ExternalStyledLink>{' '}
                  for more about how we use your data.
                </Text>
              </MessageBoxStyled>
            </TextInputHolder>
            <TextInputHolder>
              <PageNavigation />
            </TextInputHolder>
            <Submitter />
          </Form>
        </Formik>
      </Main>
    </>
  );
}
