import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Text } from '@digitalportal-ui/core';
import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import AddMemberCardDetailsCard from '../../atoms/AddMemberDetailsCard/AddMemberDetailsCard';
import { ExplanationTextWrapper } from '../../atoms/AddMemberDetailsCardExpandForm/styles';
import routes from '../../../enums/routes';
import { TStore } from '../../../redux/store';
import PageIntroduction from '../../molecules/PageIntroduction/PageIntroduction';
import Footer from '../../templates/Footer/Footer';
import { PageWrapper, PageIntroductionWrapper, AddMemberCardDetailsCardWrapper } from '../excess/styles';
import { Member, updateMember, updateMemberConsultation } from '../../../redux/slices/quote';
import { updateHelperIsProgressShown } from '../../../redux/slices/helpers';
import AddMemberDetailsCardExpandForm from '../../atoms/AddMemberDetailsCardExpandForm/AddMemberDetailsCardExpandForm';
import Header from '../../templates/Header/Header';
import PageNavigation from '../../templates/PageNavigation/PageNavigation';
import { Main } from '../../templates/styles';
import { useCustomEventListener } from '../../../lib/utils/eventHandler';
import { consultationRulesMet } from './productRules';
import { useGetPrice } from '../../../hooks/useGetPrice';
import { AddProductAddonToDataLayer } from '../../../lib/tagManager/commonFunctions';
import configData from '../../../config/config.json';

export default function Consultations(): React.JSX.Element {
  const history = useNavigate();
  const dispatch = useDispatch();
  const { getPrice, loading } = useGetPrice();

  const [memberCardsExpanded, setMemberCardsExpanded] = useState([true]);

  const { members } = useSelector((state: TStore) => state.quoteState.quote);
  const helpers = useSelector((state: TStore) => state.helpersState.helpers);

  const consultationValuesEqualForAllMembers =
    members.filter((member) => member.consultation === members.find((mem) => mem.policyHolder)?.consultation).length ===
    members.length;

  useCustomEventListener('pageNavigationBack', () => {
    history(routes.entitlements, helpers.navigateOptions);
  });

  useEffect(() => {
    members.forEach((member, index) => {
      if (!consultationRulesMet(helpers.productRules, member)) {
        dispatch(
          updateMember({
            userEditIndex: index,
            values: { consultation: helpers.productRules.consultations?.options[0].code },
          }),
        );
      }
    });
  }, []);

  useEffect(() => {
    dispatch(updateHelperIsProgressShown({ isProgressShown: true }));
  }, [dispatch]);

  useEffect(() => {
    setMemberCardsExpanded(Array(members.length).fill(true));
  }, [members?.length]);

  useCustomEventListener('pageNavigationNext', async () => {
    if (configData.FEATURES.RHL === 'true') {
      history(routes.guidedSelection, helpers.navigateOptions);
    } else {
      history(routes.excess, helpers.navigateOptions);
    }
  });
  const toggleExpandedHandler = (expanded: boolean, index: number) => {
    const newMemberCardsExpanded = [...memberCardsExpanded];
    newMemberCardsExpanded[index] = !expanded;
    setMemberCardsExpanded(newMemberCardsExpanded);
  };

  const dispatchUpdateMemberConsultation = (consultation: string, index: number) => {
    dispatch(updateMemberConsultation({ userEditIndex: index, consultation }));
  };

  const formSubmitHandler = (consultation: string) => {
    setMemberCardsExpanded(Array(members.length).fill(true));
    const consultationName = members
      .find((member) => member.policyHolder)
      ?.consultationConfig?.options.find((x) => x.value === consultation)?.displayName;
    let membersUpdatedIndex = '';
    for (let index = 0; index < members.length; index++) {
      dispatchUpdateMemberConsultation(consultation, index);
      membersUpdatedIndex += index;
      if (index !== members.length - 1) {
        membersUpdatedIndex += ',';
      }
    }
    if (consultationName) {
      AddProductAddonToDataLayer(consultationName, membersUpdatedIndex);
    }
    getPrice({ partialMember: { consultation } });
  };

  const radioClickHandler = (member: Partial<Member>, index: number) => {
    const consultationName = member.consultationConfig?.options.find(
      (consultation) => consultation.value === member.consultation,
    )?.displayName;
    if (consultationName) {
      AddProductAddonToDataLayer(consultationName, index.toString());
    }
    dispatchUpdateMemberConsultation(member.consultation ?? '', index);
    getPrice({ partialMember: member });
  };

  return (
    <>
      <Header />
      <Main maxWidth="lg">
        <PageWrapper display="flex" flexDirection="column" alignItems="center">
          <PageIntroductionWrapper>
            <PageIntroduction
              title="How many specialist consultations would you like?"
              titleTestId="title"
              subtitle={
                <>
                  <Box mb={1}>
                    {`Choose unlimited specialist consultations, or limit these to three${
                      members.length > 1 ? ' for each member' : ''
                    }.`}
                  </Box>
                  <Box mb={1}>
                    This only applies to the Outpatient diagnosis and care and Inpatient and day patient care options,
                    if you’ve chosen these.
                  </Box>
                  <Box>
                    Cancer care and Mental health care options include unlimited specialist consultations.
                    You can only use these specialist consultations for your Cancer care or Mental health treatment.
                  </Box>
                </>
              }
              subtitleTestId="subtitle"
              includeWrapper={false}
              modalTitle="How does the specialist consultations limit work?"
            >
              <Text bold mb={1}>
                If you choose 3 consultations:
              </Text>
              <Text mb={1}>
                If you need more than 3, you’ll need to pay for these yourself. We don’t count tests – such as x-rays,
                ultrasounds or MRIs – in this limit.
              </Text>
              <Text mb={1}>
                This limit only applies to Outpatient diagnosis and care or Inpatient and day patient care options. If
                you have both Outpatient diagnosis and care and Inpatient and day patient care options, the three
                consultations are shared between both types of cover. For example, you might have two consultations
                before an operation and one follow-up afterwards.
              </Text>
              <Text bold mb={1}>
                If you choose unlimited consultations:
              </Text>
              <Text mb={1}>You can have as many specialist consultations as you need.</Text>
              <Text bold mb={1}>
                Other rules to do with consultations:
              </Text>
              <Text mb={1}>
                Mental health care and Cancer care options include unlimited specialist consultations to do with these
                conditions.
              </Text>
              <Text>
                A ‘specialist’ is sometimes called a ‘consultant’. They are a medical practitioner with specialist
                training, such as a consultant surgeon.
              </Text>
            </PageIntroduction>
          </PageIntroductionWrapper>
          {members?.map((member, index) => (
            <AddMemberCardDetailsCardWrapper
              key={`${member.firstname + index}`}
              className="add-member-card-details-wrapper"
            >
              <AddMemberCardDetailsCard
                policyHolder={member.policyHolder}
                memberDetailsProps={member}
                expanded={memberCardsExpanded[index]}
                toggleExpanded={(expanded) => {
                  toggleExpandedHandler(expanded, index);
                }}
                statusText={member.consultation}
                cardContentChildren={
                  consultationRulesMet(helpers.productRules, member) ? (
                    <AddMemberDetailsCardExpandForm
                      isMemberPolicyHolder={member.policyHolder}
                      isApplyToAllButtonAvailable={members.length > 1}
                      selectedFieldValue={member.consultation}
                      fieldOptions={member.consultationConfig?.options}
                      selectedFieldValuesEqualForAllMembers={consultationValuesEqualForAllMembers}
                      formSubmitHandler={formSubmitHandler}
                      radioClickHandler={(consultation) => {
                        radioClickHandler(
                          { id: member.id, consultation, consultationConfig: member.consultationConfig },
                          index,
                        );
                      }}
                      explanationText={
                        <>
                          <Text mb={1}>
                            If you need more than 3 specialist outpatient consultations, you’ll need to pay for these
                            yourself.
                          </Text>
                          <Text mb={1}>
                            This limit only affects your Outpatient diagnosis and care and Inpatient and day patient
                            care options, if you’ve chosen these.
                          </Text>
                          <Text>
                            Everything else includes unlimited specialist consultations. If you have both Outpatient
                            diagnosis and care and Inpatient and day patient care options this consultations limit is
                            shared between these. For example, you might have two consultations before an operation, and
                            one follow-up afterwards.
                          </Text>
                        </>
                      }
                      explanationTextVisibleOnlyOnValue="3"
                      radioBlockWrapperWidthMd="50%"
                    />
                  ) : (
                    <ExplanationTextWrapper data-testid="text-explanation">
                      <Text>
                        The specialist consultations limit doesn’t apply to this member’s cover as they have not
                        selected Outpatient diagnosis and care and Inpatient and day patient care
                      </Text>
                    </ExplanationTextWrapper>
                  )
                }
              />
            </AddMemberCardDetailsCardWrapper>
          ))}
          <PageNavigation wrapperMarginDisabled isNextButtonDisabled={loading} />
        </PageWrapper>
        <Footer data-testid="footer" isPrice priceLoading={loading} />
      </Main>
    </>
  );
}
