import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { H3, Text, Modal } from '@digitalportal-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { Box } from '@mui/material';
import { Form, Formik } from 'formik';

import { useCustomEventListener } from '../../../lib/utils/eventHandler';
import { Checkbox } from '../../atoms';
import { TStore } from '../../../redux/store';
import routes from '../../../enums/routes';
import RadioVariants from '../../../enums/radioVariants';
import CheckboxVariants from '../../../enums/checkboxVariants';
import {
  updateHelperMajorHealthQuestions,
  updateHelperIsProgressShown,
  updateModalInfo,
} from '../../../redux/slices/helpers';
import { MajorHealthQuestionType, MajorHealthAnswerOptions } from '../../../redux/slices/helpers/helpers.types';
import { Member } from '../../../redux/slices/quote/quote.types';
import { updateMemberMajorHealthQuestions } from '../../../redux/slices/quote/quote.slice';

import Header from '../../templates/Header/Header';
import PageNavigation from '../../templates/PageNavigation/PageNavigation';
import { Main } from '../../templates/styles';

import PageIntroduction from '../../molecules/PageIntroduction/PageIntroduction';
import RadioBlock from '../../molecules/RadioBlock/RadioBlock';
import { scrollToFirstError } from './scrollToFirstError';

import {
  PageWrapper,
  PageIntroductionWrapper,
  RFQBox,
  RFQBoxQuestionWrapper,
  RFQBoxInfoTextWrapper,
  RadioBlockContainer,
  RadioBlockWrapper,
  CheckboxContainer,
  CheckboxContainerQuestionWrapper,
  CheckboxWrapper,
  ModalLink,
} from './styles';
import mhqPageContent from '../../../content/mhqPageContent.json';
import ErrorMessageBox from '../../molecules/ErrorMessageBox/errorMessageBox';
import { InfoLink } from '../../atoms/Link/Link';
import MhqModal from './mhqModal';
import TextExclusionWrapper, { getFoundExclusionText } from './textExclusionWrapper';
import MembershipHandbook from '../../../assets/pdfs/AXA Health Plan Generic Handbook.pdf';
import MembershipHandbook2024 from '../../../assets/pdfs/AXA Health Plan Handbook.pdf';
import { isCoverStartDateBeforeCutOffDate } from '../../../lib/utils/isCoverStartDateBeforeCutOffDate';

type RFQFormProps = {
  initialValues: Record<string, string | undefined>;
  question: MajorHealthQuestionType;
  isFavourable: boolean | undefined;
  onClickRadioHandler: (answer: string, isFavourable: boolean) => void;
  onClickCheckboxHandler: (memberIndex: number, checked: boolean, answer: MajorHealthAnswerOptions[]) => void;
  members: Member[];
  questionAnswered: (question: string) => boolean;
};

const RFQForm = ({
  initialValues,
  question,
  isFavourable,
  onClickRadioHandler,
  onClickCheckboxHandler,
  members,
  questionAnswered,
}: RFQFormProps): React.JSX.Element => {
  const dispatch = useDispatch();
  const [closeError, setCloseError] = useState(false);
  const unansweredQuestion = !questionAnswered(question.code);
  const [currentMhqModal, setCurrentMhqModal] = useState<{ open: boolean; value: string }>({
    open: false,
    value: '',
  });
  const helperMHQS = useSelector((state: TStore) => state.helpersState.helpers.majorHealthQuestions);

  useCustomEventListener('pageNavigationNext', () => {
    setCloseError(false);
  });

  const isErroredHandler = () =>
    !!(
      unansweredQuestion &&
      helperMHQS.nextButtonClicked &&
      !helperMHQS.answers?.find((a) => a.code === question.code)
    );

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      onSubmit={() => {
        jest.fn();
      }}
    >
      {({ setFieldValue }) => (
        <Form>
          <Box display="flex" flexDirection="column">
            <RadioBlockContainer>
              {question.answers.map((answer) => (
                <RadioBlockWrapper
                  key={`radioButton-${answer.value}`}
                  onClick={() => {
                    onClickRadioHandler(answer.value, answer.isFavourable);
                    if (answer.isFavourable && members.length > 1) {
                      members.forEach((member: Member, memberIndex: number) => {
                        setFieldValue(`${question.code}-${memberIndex}`, answer.value);
                      });
                    }
                  }}
                  data-testid={`radioButton-${answer.value}-${question.code}`}
                >
                  <RadioBlock
                    isErrored={isErroredHandler()}
                    variant={RadioVariants.brick}
                    value={answer.value}
                    name={question.code}
                  >
                    <Text semibold>{answer.displayName}</Text>
                  </RadioBlock>
                </RadioBlockWrapper>
              ))}
            </RadioBlockContainer>
            {isFavourable !== undefined && !isFavourable && members.length > 1 && (
              <CheckboxContainer>
                <CheckboxContainerQuestionWrapper>
                  <Text textAlign="left">Who had this condition?</Text>
                </CheckboxContainerQuestionWrapper>
                {members.map((member: Member, memberIndex: number) => (
                  <CheckboxWrapper key={`${question.code}-${member.title}-${member.firstname}-${member.lastname}`}>
                    <Checkbox
                      isErrored={!!(unansweredQuestion && helperMHQS.nextButtonClicked)}
                      variant={CheckboxVariants.brick}
                      name={`${question.code}-${memberIndex}`}
                      testId={`${question.code}-${memberIndex}`}
                      isChecked={
                        member.majorHealthQuestions[question.code]
                          ? !question.answers.find(
                              (answer) => answer.value === member.majorHealthQuestions[question.code],
                            )?.isFavourable
                          : false
                      }
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        onClickCheckboxHandler(memberIndex, e.target.checked, question.answers);
                      }}
                    >
                      <Text className="axa-mask" semibold>
                        {`${member.firstname} ${member.lastname} ${member.policyHolder ? '(you)' : ''}`}
                      </Text>
                    </Checkbox>
                  </CheckboxWrapper>
                ))}
              </CheckboxContainer>
            )}
            {isFavourable !== undefined &&
              getFoundExclusionText({ questionCode: question.code, members, isFavourable }).length === 1 && (
                <TextExclusionWrapper questionCode={question.code} members={members} isFavourable={isFavourable} />
              )}
            {mhqPageContent.find((mhqContent) => mhqContent.code === question.code)?.modalTitle && (
              <>
                <Box sx={{ marginTop: { xs: '16px', md: '32px' } }}>
                  <InfoLink
                    link={mhqPageContent.find((mhqContent) => mhqContent.code === question.code)?.modalTitle || ''}
                    testId={`${question.code}-link`}
                    onClickHandler={() => {
                      setCurrentMhqModal({
                        ...currentMhqModal,
                        open: true,
                        value: question.code,
                      });
                      dispatch(
                        updateModalInfo({
                          isModalOpen: true,
                          modalTitle: mhqPageContent.find((x) => x.code === question.code)?.modalTitle ?? '',
                        }),
                      );
                    }}
                  />
                </Box>
                <MhqModal
                  code={question.code}
                  currentMhqModal={currentMhqModal}
                  setCurrentMhqModal={setCurrentMhqModal}
                />
              </>
            )}
          </Box>
          {unansweredQuestion && helperMHQS.nextButtonClicked && !closeError && (
            <Box className="error-unanswered" mt="16px">
              <ErrorMessageBox closeError={setCloseError} />
            </Box>
          )}
        </Form>
      )}
    </Formik>
  );
};

export default function MajorHealthQuestions(): React.JSX.Element {
  const history = useNavigate();
  const dispatch = useDispatch();
  const modalTitle = "Got a condition that wasn't listed?";
  const modalPhoneNumber = '0800 169 7593';
  const [scrollToQuestionNumber, setScrollToQuestionNumber] = useState(0);

  const { members, coverStartDate } = useSelector((state: TStore) => state.quoteState.quote);
  const helperMHQS = useSelector((state: TStore) => state.helpersState.helpers.majorHealthQuestions);
  const navigateOptions = useSelector((state: TStore) => state.helpersState.helpers.navigateOptions);
  const [conditionModal, setConditionModal] = useState(false);

  const modalPDFHandbookLink = {
    documentTitle: 'handbook',
    link: isCoverStartDateBeforeCutOffDate(coverStartDate) ? MembershipHandbook : MembershipHandbook2024,
  };

  const questionAnswered = (questionCode: string) => {
    const helperAnswer = helperMHQS.answers?.find((a) => a.code === questionCode);
    if (members.length > 1 && helperAnswer !== undefined && !helperAnswer.isFavourable) {
      return members.some((member) => member.majorHealthQuestions[questionCode] === helperAnswer.answerValue);
    }
    return helperAnswer !== undefined;
  };

  const isValid = () => Object.values(helperMHQS.questions).every((question) => questionAnswered(question.code));

  useEffect(() => {
    const element = document.querySelector(`.question-${scrollToQuestionNumber}`);
    element?.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }, [scrollToQuestionNumber]);

  useEffect(() => {
    dispatch(updateHelperIsProgressShown({ isProgressShown: true }));
  }, [dispatch]);

  useCustomEventListener('pageNavigationNext', () => {
    dispatch(updateHelperMajorHealthQuestions({ nextButtonClicked: true }));

    if (isValid()) {
      setConditionModal(true);
      dispatch(updateModalInfo({ isModalOpen: true, modalTitle }));
    } else {
      const timer = setInterval(() => {
        if (scrollToFirstError('.error-unanswered')) {
          clearInterval(timer);
        }
      }, 50);
    }
  });

  useCustomEventListener('pageNavigationBack', () => {
    history(routes.additionalMembers, navigateOptions);
  });

  const onClickRadioHandler = (
    questionCode: string,
    questionIndex: number,
    answer: string,
    isFavourable: boolean,
    membersLength: number,
  ) => {
    const helperAnswers = helperMHQS.answers.filter((a) => a.code !== questionCode);
    helperAnswers.push({
      code: questionCode,
      answerValue: answer,
      isFavourable,
    });
    dispatch(updateHelperMajorHealthQuestions({ answers: helperAnswers }));

    if (membersLength === 1) {
      dispatch(
        updateMemberMajorHealthQuestions({
          userEditIndex: 0,
          majorHealthQuestions: { [questionCode]: answer },
        }),
      );
    } else if (membersLength > 1) {
      const favourableAnswer =
        helperMHQS?.questions
          ?.find((question) => question.code === questionCode)
          ?.answers?.find((questionAnswer) => questionAnswer.isFavourable)?.value ?? '';
      members.forEach((member, memberIndex) => {
        dispatch(
          updateMemberMajorHealthQuestions({
            userEditIndex: memberIndex,
            majorHealthQuestions: { [questionCode]: favourableAnswer }, // Setting to favourable answer no matter the selected answer because multiple member journey uses checkboxes to set unfavourable answer
          }),
        );
      });
    }
    if (isFavourable) {
      setScrollToQuestionNumber(questionIndex + 1);
    }
  };

  const onClickCheckboxHandler = (
    questionId: string,
    checked: boolean,
    answers: MajorHealthAnswerOptions[],
    memberIndex: number,
  ) => {
    const answerValue = checked
      ? answers.find((answer) => !answer.isFavourable)?.value
      : answers.find((answer) => answer.isFavourable)?.value;
    if (answerValue !== undefined) {
      dispatch(
        updateMemberMajorHealthQuestions({
          userEditIndex: memberIndex,
          majorHealthQuestions: { [questionId]: answerValue },
        }),
      );
    }
  };

  const Questions = useMemo(
    () =>
      helperMHQS.questions.map((question, questionIndex) => {
        const answer = helperMHQS.answers.find((a) => a.code === question.code);
        const initialValues = { [question.code]: answer?.answerValue };
        const isFavourable = answer?.isFavourable;

        if (members.length > 1) {
          members.forEach((member, memberIndex) => {
            initialValues[`${question.code}-${memberIndex}`] = member.majorHealthQuestions[question.code];
          });
        }

        return (
          <RFQBox className={`question-${questionIndex}`} key={question.code}>
            <RFQBoxQuestionWrapper>
              <H3 width="100%" textAlign="left" bold data-testid={`question-${question.code}`}>
                {members.length > 1 ? question.questionPlural : question.question}
              </H3>
            </RFQBoxQuestionWrapper>
            {mhqPageContent.find((mhqContent) => mhqContent.code === question.code) && (
              <RFQBoxInfoTextWrapper>
                {mhqPageContent
                  .find((mhqContent) => mhqContent.code === question.code)
                  ?.[members.length === 1 ? 'infoText' : 'infoTextPlural'].map((infoText, index) => (
                    <Text
                      align="left"
                      ml={infoText.includes('\t') ? 4 : 0}
                      data-testid={`question-subtext-${question.code}-${index}`}
                      key={infoText.replace(' ', '-')}
                      style={{ display: infoText.includes('\t') ? 'list-item' : '' }}
                    >
                      {infoText}
                    </Text>
                  ))}
              </RFQBoxInfoTextWrapper>
            )}
            <RFQForm
              initialValues={initialValues}
              question={question}
              isFavourable={isFavourable}
              onClickRadioHandler={(answerValue, favourable) => {
                onClickRadioHandler(question.code, questionIndex, answerValue, favourable, members.length);
              }}
              onClickCheckboxHandler={(memberIndex, checked, answers) => {
                onClickCheckboxHandler(question.code, checked, answers, memberIndex);
              }}
              members={members}
              questionAnswered={questionAnswered}
            />
          </RFQBox>
        );
      }),
    [helperMHQS, questionAnswered],
  );

  return (
    <>
      <Header />
      <Main maxWidth="lg">
        <PageWrapper display="flex" flexDirection="column" alignItems="center">
          <PageIntroductionWrapper>
            <PageIntroduction
              title="Asking you about your health"
              titleTestId="title"
              subtitle={
                <>
                  <Box mb={1}>
                    These questions help us to work out which options we can offer you, how much you pay, and whether
                    we’ll pay claims later on.
                  </Box>
                  {members.length > 1 ? (
                    <>
                      <Box mb={1}>Please answer these questions thinking about everyone that you want to cover.</Box>
                      <Box mb={1}>
                        If anyone has had a test for a new condition or symptoms, and the test showed they definitely
                        didn’t have the condition, you can answer ‘no’.
                      </Box>
                      <Box>
                        If you’re not sure, or if anyone is waiting for the results of a test or screening, please call
                        us and we can talk you through how to answer.
                      </Box>
                    </>
                  ) : (
                    <>
                      <Box mb={1}>
                        If you’ve had a test for a new condition or symptoms, and the test showed you definitely didn’t
                        have the condition, you can answer ‘no’.
                      </Box>
                      <Box>
                        If you’re not sure, or if you’re waiting for the results of a test or screening, please call us
                        and we can talk you through how to answer.
                      </Box>
                    </>
                  )}
                </>
              }
              subtitleTestId="subtitle"
              includeWrapper={false}
              modalTitle="What we mean when we say ‘tests, treatment or follow-ups’"
            >
              <Text mb={1}>
                When we say tests, treatment or follow-ups, we mean any kind of medical or surgical attention or advice
                to diagnose or treat an illness or injury. For these questions, we don’t count the following as tests,
                treatment or follow-ups:
              </Text>
              <Text display="list-item" ml={4}>
                medication prescribed by your GP.
              </Text>
              <Text display="list-item" ml={4}>
                checks that you’ve chosen to have for your own peace of mind, which a doctor didn’t tell you to have.
              </Text>
            </PageIntroduction>
          </PageIntroductionWrapper>
          {Questions}
          <Modal
            variant="info"
            title={modalTitle}
            dataTestid="condition-modal"
            open={conditionModal}
            onClose={() => {
              setConditionModal(!conditionModal);
              dispatch(updateModalInfo({ isModalOpen: false, modalTitle: '' }));
              history(routes.entitlements, navigateOptions);
            }}
            customCloseButtonText="Okay"
          >
            <Box>
              <Text mb={1}>
                Health insurance isn&apos;t designed to cover any condition that you already have: it&apos;s designed to
                cover new conditions.
              </Text>
              <Text mb={1}>
                Your membership won&apos;t cover any conditions you&apos;ve had in the three years before your cover
                starts. This includes if you&apos;ve had symptoms of a condition that hasn&apos;t been diagnosed yet.
              </Text>
              <Text mb={1}>
                If you want cancer cover, we won&apos;t cover any cancer you&apos;ve had at any point, including if it
                comes back or spreads.
              </Text>
              <Text>
                For more information about how we cover existing conditions, see our{' '}
                <ModalLink to={modalPDFHandbookLink.link} target="_blank" aria-label="AXA Health Plan Handbook">
                  {modalPDFHandbookLink.documentTitle}
                </ModalLink>{' '}
                or give us a call on{' '}
                <ModalLink to={`tel:${modalPhoneNumber}`} aria-label={modalPhoneNumber}>
                  {modalPhoneNumber}
                </ModalLink>{' '}
                and we&apos;ll be happy to help.
              </Text>
            </Box>
          </Modal>
          <PageNavigation wrapperMarginDisabled />
        </PageWrapper>
      </Main>
    </>
  );
}
